<template>
  <el-dialog
    class="import-dialog"
    :title="`批量导入`"
    :visible.sync="visible"
    :before-close="handleBeforeClose"
    :close-on-click-modal="false"
  >
    <div>
      <div class="down-tip">
        可下载Excel模板文件，在文件内新建的多条授予记录，再导入文件
      </div>
      <!-- <a :href="awardOption" target="_blank">下载新建期权授予模板文件</a> -->
      <el-button type="text" class="a" @click="openBlank(awardOption)"
        >下载新建期权授予模板文件</el-button
      >
      <el-form
        ref="form"
        :model="info"
        v-loading="getDetailLoading"
        :rules="formRules"
        hide-required-asterisk
        label-width="75px"
      >
        <el-form-item label="选择文件" prop="fileName">
          <div class="flex-h uploader_box">
            <el-input
              label=""
              v-model="info.fileName"
              placeholder="请选择文件"
              readonly
              clearable
            ></el-input>
            <el-upload
              class="file-uploader"
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              action=""
              :http-request="upload"
              :show-file-list="false"
            >
              <el-button size="medium" class="choose">···</el-button>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit"
        :disabled="!info.fileName"
        >提交</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import api from "@/api";
import dialogImport from "@/mixins/dialogImport";
import { deepClone } from "@/utils/core";
import { awardOption } from "@/utils/file-mode";
const defaultInfo = {
  fileName: "",
};

export default {
  mixins: [dialogImport],
  components: {},
  data() {
    return {
      awardOption,
      infoBackup: deepClone(defaultInfo),
      info: deepClone(defaultInfo),
      formRules: {
        fileName: [
          { required: true, message: "请选择文件", trigger: "change" },
        ],
      },
      file: null,
    };
  },
  methods: {
    upload({ file }) {
      this.file = file;
      this.info.fileName = file.name;
    },

    getDetail() {},
    async submitMethod() {
      let formData = new FormData();
      formData.append("file", this.file);
      const res = await api.awarded.optionAwardImport(formData);
      // console.log(res);
      const { status, msg } = res.data;
      if (status !== "0") {
        this.$message.error(msg || "未知错误");
        throw new Error(msg || "未知错误");
      }
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.file = null;
      this.info = deepClone(defaultInfo);
      this.infoBackup = deepClone(defaultInfo);
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss" scoped>
.import-dialog {
  /deep/ .el-dialog {
    padding-left: 24px;
    padding-right: 24px;
  }
  /deep/ .el-form-item {
    margin-bottom: 4px;
  }
  .a {
    text-decoration: underline;
    line-height: 22px;
    margin-bottom: 16px;
  }
  .down-tip {
    font-size: 14px;
    color: #333333;
    line-height: 22px;
    margin-top: 6px;
  }
  .uploader_box {
    /deep/ .el-input {
      width: 292px;
    }
    .file-uploader {
      .choose {
        margin-left: 12px;
        font-weight: 600;
      }
    }
  }
}
</style>
