import tableMng from "@/utils/tableMng";
export default {
  data() {
    return {
      tableMng,
      rowHeight: 0,
      tableLoading: false,
      tableData: [],
      activeRow: 0,
      relation_data: {},
      edit_data: {},
      requested: false,
      timeOut: null,
    };
  },
  computed: {
    //是否显示缺省页
    showDataDemo() {
      return this.tableData && this.tableData.length > 0;
    },
  },
  mounted() {
    // 获取下半部分高度
    this.$nextTick(() => {
      if (this.$refs.row)
        this.rowHeight = `calc(100% - ${this.$refs.row.offsetTop}px)`;
    });
    this.timeOut = setTimeout(() => {
      this.tableLoading = false;
      this.loading = false;
    }, 8000);
  },
  methods: {
    // 获取表格数据
    async getList() {
      if (this.timeOut) clearTimeout(this.timeOut);
      this.tableLoading = true;
      const data = await this.getApiTableData();
      if (data) {
        this.tableData = data.list;
        this.total = data.total;
      }
      //默认选中第一行
      this.$nextTick(() => {
        this.tableLoading = false;
        this.requested = true;
        if (this.tableData.length > 0)
          this.$refs.table.setCurrentRow(this.tableData[this.activeRow]);
        else this.activeRow = 0;
        this.relation_data = this.tableData[this.activeRow] || {};
      });
    },
    handleEdit(index, row) {
      this.edit_data = row ? row : {};
      this.visible = true;
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    // 选择行
    rowClick(row) {
      this.relation_data = row;
      if (this.activeRow != row.index) {
        this.activeRow = row.index;
        this.rowClickMethod();
      }
    },
    rowClickMethod() {},
    // 排序
    changeSort({ prop, order }) {
      this.queryCondition["sort"] = prop;
      this.queryCondition.order = order === "ascending" ? "asc" : "desc";
      // 无排序时 恢复默认
      if (order === null) this.queryCondition["sort"] = this.defaultSortWord;
      this.getList();
    },
    // 保存
    handleSave() {
      this.handleClose();
      this.getList();
    },
  },
  destroyed() {
    clearTimeout(this.timeOut);
  },
};
