<template>
  <div class="in_option">
    <search-collapse
      :senior="false"
      @handleSearch="getList"
      @handleAdd="handleEdit"
    >
      <el-form class="el-form-custom" :inline="true" :model="queryCondition">
        <el-form-item label="">
          <el-input
            v-model="queryCondition.name"
            placeholder="请输入计划名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input
            v-model="queryCondition.code"
            placeholder="请输入完整的计划编号"
            clearable
          ></el-input>
        </el-form-item>
        <!-- <div
          style="width: 100px"
          v-for="item in [1, 2, 3, 4, 5]"
          :key="item"
        ></div> -->
      </el-form>
    </search-collapse>
    <div class="row" ref="row" :style="`height:${rowHeight}`">
      <div class="col" v-show="showDataDemo">
        <div class="col-left">
          <el-table
            :data="tableData"
            highlight-current-row
            v-loading="tableLoading"
            @sort-change="changeSort"
            @row-click="rowClick"
            :row-class-name="tableRowClassName"
            ref="table"
            height="calc(100% - 64px)"
            class="custom_border"
          >
            <af-table-column prop="code" label="计划编号" sortable="custom" />
            <el-table-column prop="id" label="操作" min-width="125px">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleEdit(scope.$index, scope.row)"
                >
                  修改
                </el-button>
                <el-divider direction="vertical"></el-divider>
                <el-button
                  type="text"
                  class="del_button"
                  @click="handleDelete(scope.$index, scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
            <af-table-column prop="name" label="计划名称">
              <template slot-scope="scope">
                <chacter-overflow :limit="20" :text="scope.row.name || '- -'" />
              </template>
            </af-table-column>
            <af-table-column prop="platform_name" label="持股平台">
              <template slot-scope="scope">
                <TextOverflow
                  :limit="20"
                  :text="scope.row.platform_name || '- -'"
                />
              </template>
            </af-table-column>
            <el-table-column
              prop="mode"
              label="持股方式"
              min-width="130px"
              sortable="custom"
            >
              <!-- <template slot="header">
                <el-dropdown @command="handleCommand">
                  <span class="el-dropdown-link">
                    持股方式<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="item in $addAllSelect(
                        tableMng.getTable('hold_mode')
                      )"
                      :key="item.id"
                      :command="`mode-${item.id}`"
                      >{{ item.name }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template> -->
              <template slot-scope="scope">
                {{ tableMng.getNameById("hold_mode", scope.row.mode) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="currency"
              label="币种"
              min-width="100px"
              sortable="custom"
            >
              <template slot-scope="scope">
                {{ tableMng.getNameById("currency_list", scope.row.currency) }}
              </template>
            </el-table-column>

            <af-table-column
              prop="price"
              label="行权参考价"
              align="right"
              sortable="custom"
            />
            <af-table-column
              prop="total"
              label="总股数"
              min-width="180px"
              sortable="custom"
            />
            <af-table-column
              prop="awarded"
              label="已授予数"
              sortable="custom"
            />
            <af-table-column
              prop="surplus"
              label="剩余股数"
              sortable="custom"
            />

            <el-table-column prop="ctime" label="创建时间" min-width="195px" />
            <af-table-column prop="creator" label="创建人" min-width="80px" />
            <el-table-column prop="mtime" label="修改时间" min-width="195px" />
            <af-table-column prop="modifier" label="修改人" min-width="80px" />
          </el-table>
          <!-- 分页 -->
          <pagination
            :total="total"
            :page-number.sync="queryCondition.page"
            :page-size.sync="queryCondition.limit"
            @pagination="getList"
          />
        </div>
        <div class="col-right">
          <el-tabs v-model="editableTabsValue" type="card">
            <el-tab-pane label="成熟与行权统计" name="0">
              <right-sta :data="relation_data" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <Empty v-show="!showDataDemo && requested" />
    </div>
    <Edit
      :data="edit_data"
      :visible="visible"
      @onClose="handleClose"
      @onSave="handleSave"
    />
  </div>
</template>
<script>
import SearchCollapse from "@/components/base/search-collapse";
import Empty from "@/components/base/empty";
import RightSta from "./components/RightSta.vue";
import Edit from "./components/Edit.vue";
import Pagination from "@/components/base/pagination";
import tableLife from "@/mixins/tableLife.js";
import TextOverflow from "../../../components/base/text-overflow";
import api from "@/api";
export default {
  mixins: [tableLife],
  components: {
    SearchCollapse,
    TextOverflow,
    Pagination,
    RightSta,
    Empty,
    Edit,
  },
  data() {
    return {
      editableTabsValue: 0,
      defaultSortWord: "code",
      total: 0,
      queryCondition: {
        name: "",
        code: "",
        sort: "code",
        order: "desc",
        page: 1,
        limit: 20,
      },
      visible: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getApiTableData() {
      return api.incentive.optionPlanList(this.queryCondition);
      // const data = await api.incentive.optionPlanList(this.queryCondition);
      // return data;
    },
    handleDelete(index, row) {
      let plan_id = row.plan_id;
      if (plan_id) {
        this.$confirm(`确定删除吗？`, "删除", {})
          .then(async () => {
            await api.incentive.optionPlanDel({ plan_id });
            this.$message.success("删除成功！");
            this.getList();
          })
          .catch(() => {});
      }
    },
    // 保存
    handleSave() {
      this.handleClose();
      this.getList();
    },
    // handleCommand(value) {
    //   const key = value.split("-")[0],
    //     val = value.split("-")[1];
    //   this.queryCondition[key] = val;
    //   this.getList();
    // },
    // 关闭编辑模态窗
    handleClose() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.in_option {
  height: 100%;
  .el-form-custom {
    text-align: right;
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .export {
    padding-left: 12px;
    .img_icon {
      width: 20px;
      margin-right: 8px;
    }
  }
}
</style>
