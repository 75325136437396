<template>
  <div class="maturity">
    <el-table
      :data="tableData"
      highlight-current-row
      v-loading="tableLoading"
      @sort-change="changeSort"
      @row-click="rowClick"
      :row-class-name="tableRowClassName"
      ref="table"
      class="custom_border"
      height="calc(100% - 64px)"
    >
      <el-table-column prop="" label="成熟操作" min-width="110px">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status === 'N'"
            type="text"
            @click="handleConfirm(scope.$index, scope.row)"
          >
            确认成熟
          </el-button>
          <span v-else>---</span>
        </template>
      </el-table-column>
      <af-table-column prop="seq" label="批次" />
      <el-table-column prop="mature_date" label="成熟日期" min-width="140px" />
      <el-table-column prop="mature_rate" label="可成熟比例" min-width="120px">
        <template slot-scope="scope">
          {{ scope.row.mature_rate | number_format }}%
        </template>
      </el-table-column>
      <af-table-column prop="expect_count" label="可成熟数" />
      <af-table-column prop="actual_count" label="已成熟数" />
      <el-table-column prop="status" label="状态" min-width="90px">
        <template slot-scope="scope">
          {{ tableMng.getNameById("mature_status", scope.row.status) }}
        </template>
      </el-table-column>

      <el-table-column prop="ctime" label="创建时间" min-width="195px" />
      <af-table-column prop="creator" label="创建人" min-width="80px" />
      <el-table-column prop="mtime" label="修改时间" min-width="195px" />
      <af-table-column prop="modifier" label="修改人" min-width="80px" />
    </el-table>
  </div>
</template>
<script>
import api from "@/api";
import tableLife from "@/mixins/tableLife.js";
export default {
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
  watch: {
    data(value) {
      const { award_id } = value;
      if (award_id) this.getList();
    },
  },
  mixins: [tableLife],
  components: {},
  data() {
    return {
      total: 0,
      queryCondition: {
        sort: "ctime",
        order: "desc",
      },
      defaultSortWord: "",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getApiTableData() {
      if (!this.data.award_id) return;
      return api.awarded.optionMatureList({
        award_id: this.data.award_id,
      });
      // const data = await api.awarded.optionMatureList({
      //   award_id: this.data.award_id,
      // });
      // return data;
    },
    handleConfirm(index, row) {
      let id = row.id,
        mature_date = row.mature_date;
      let message = "确认执行成熟操作吗？";
      const nowDay = this.$dayjs().format("YYYY-MM-DD");
      if (nowDay !== mature_date) {
        const nowDayTime = this.$dayjs().unix(),
          rowDayTime = this.$dayjs(mature_date).unix();
        message =
          nowDayTime > rowDayTime
            ? "已过成熟日，操作后无法回退，确定成熟吗？"
            : "还未到成熟日，操作后无法回退，确定提前成熟吗？";
      }
      if (id) {
        this.$confirm(`${message}`, "修改授予状态", {
          //   dangerouslyUseHTMLString: true,
        })
          .then(async () => {
            await api.awarded.optionMatureConfirm({ id });
            this.$message.success("操作成功！");
            this.getList();
            this.$parent.$parent.$parent.getList();
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.maturity {
  height: 100%;
}
</style>
