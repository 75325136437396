import tableMng from "@/utils/tableMng";
export default {
  props: {
    data: {
      type: Object,
      default: () => null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible(value) {
      if (value) this.getDetail();
    },
  },
  data() {
    return {
      tableMng,
      submitLoading: false,
      getDetailLoading: false,
    };
  },
  created() {},
  methods: {
    handleSubmit() {
      // console.log(this.info);
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            this.submitLoading = true;
            await this.submitMethod();
            this.submitLoading = false;
            this.handleClose();
            this.$emit("onSave");
            this.$message.success("提交成功");
          } catch (e) {
            console.log(e);
            this.submitLoading = false;
          }
        } else {
          // this.$message.error("请确认格式是否正确");
        }
      });
    },
    handleBeforeClose() {
      // console.log(this.info, this.infoBackup);
      if (JSON.stringify(this.infoBackup) === JSON.stringify(this.info)) {
        this.handleClose();
      } else {
        this.$confirm("信息修改后未提交，确认关闭？", "提示", {
          type: "warning",
        })
          .then(() => {
            this.handleClose();
          })
          .catch(() => {});
      }
    },
  },
};
