var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"right-sta"},[_c('div',{staticClass:"flex-h flex-vc flex-jsb"},[_c('div',{staticClass:"left"},[_c('ChartNum',{attrs:{"name":"授予人次","num":_vm.option_stat.number,"backgroundImage":"","shadowColor":""}}),_vm._l((_vm.optionStatRe),function(item){return _c('ChartNum',{key:item.color,attrs:{"name":item.name,"num":item.percent,"tipText":item.tipText || '',"backgroundImage":item.lineColor,"shadowColor":item.shadowColor}})})],2),_c('div',{staticClass:"chart-box"},[_c('Ring',{attrs:{"name":'已授予',"subtext":parseFloat(_vm.data.total) == 0
            ? '0%'
            : (
                (
                  parseFloat(_vm.option_stat.award_qty) / parseFloat(_vm.data.total)
                ).toFixed(2) * 100
              ).toFixed(2) + '%',"value":parseFloat(_vm.data.total) == 0
            ? 0
            : (
                (parseFloat(_vm.option_stat.award_qty) * 100) /
                parseFloat(_vm.data.total)
              ).toFixed(2),"color1":'#FF6B99',"color2":'#f0427a',"color3":'#FF1960',"shadowcolor":'rgba(255,107,153,0.5)',"id":'nujk',"subColor":'#ECF0F3',"height":172}})],1)]),_c('div',{staticClass:"flex-h flex-vc flex-jsb mt-20"},[_c('div',{staticClass:"left"},[_c('ChartNum',{attrs:{"name":"授予总数(股)","num":_vm.option_stat.award_qty,"backgroundImage":"","shadowColor":""}}),_c('ChartNum',{attrs:{"name":"成熟总数(股)","num":_vm.option_stat.mature_qty,"tipText":"","backgroundImage":"linear-gradient(90deg, #F06000 0%, #FF9651 100%);","shadowColor":"rgba(255,160,97,0.50)"}})],1),_c('div',{staticClass:"chart-box"},[_c('Canvasdash',{attrs:{"id":'chartstop3',"value":parseFloat(_vm.option_stat.award_qty) == 0
            ? 0
            : parseFloat(_vm.option_stat.mature_qty) /
              parseFloat(_vm.option_stat.award_qty),"height":74,"color1":'#F06000',"color2":'#FF9651',"shadowcolor":'rgba(255,160,97,0.5)',"shadowcolor2":'rgba(255,160,97,0.3)',"name":'已成熟'}})],1)]),_c('div',{staticClass:"flex-h flex-vc flex-jsb mt-20"},[_c('div',{staticClass:"left"},[_c('ChartNum',{attrs:{"name":"成熟总数(股)","num":_vm.option_stat.mature_qty,"backgroundImage":"","shadowColor":""}}),_c('ChartNum',{attrs:{"name":"行权总数(股)","num":_vm.option_stat.actual_qty,"tipText":"","backgroundImage":"linear-gradient(224deg, #57EB99 0%, #0DCD63 100%)","shadowColor":"rgba(255,160,97,0.50)"}})],1),_c('div',{staticClass:"chart-box"},[_c('Canvasdash',{attrs:{"id":'chartsbottom12',"value":parseFloat(_vm.option_stat.mature_qty) == 0
            ? 0
            : parseFloat(_vm.option_stat.actual_qty) /
              parseFloat(_vm.option_stat.mature_qty),"height":74,"color1":'#0DCD63',"color2":'#57EB99',"shadowcolor":'rgba(87,235,153,0.5)',"shadowcolor2":'rgba(87,235,153,0.3)',"name":'已行权'}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }