<template>
  <div
    class="search-collapse"
    :style="`${
      senior ? `height:${opened ? outHeight : defaultHeight}px` : 'height:auto'
    }`"
  >
    <!-- :style="`height:${title && title.length > 0 ? '128' : '64'}px`" -->
    <div class="search-collapse-title" v-show="title && title.length">
      {{ title }}
    </div>
    <div class="search-collapse-container flex-h">
      <slot name="search-left">
        <el-button
          type="primary"
          class="add"
          icon="el-icon-plus"
          @click="handleAdd"
          >新建</el-button
        >
      </slot>

      <div
        class="flex1 search-collapse-container-center"
        :style="`${
          senior ? `height:${opened ? height : 36}px;` : 'height:auto'
        }`"
      >
        <!--  -->
        <div ref="heightEle">
          <slot></slot>
        </div>
      </div>

      <div class="search-right flex-h flex-hc">
        <div
          class="search-collapse-senior flex-h flex-vc"
          @click="opened = !opened"
          v-show="senior"
        >
          <span>更多</span>
          <img
            :class="['icon-arrow', opened ? 'is-opened' : '']"
            src="@/assets/images/base/arrow-down@2x.png"
            alt=""
          />
        </div>
        <slot name="search-right">
          <el-button type="primary" @click="handleSearch" class="search"
            >查询</el-button
          >
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      //标题
      type: String,
      default: "",
    },
    senior: {
      //高级搜索按钮
      type: Boolean,
      default: false,
    },
  },
  computed: {
    defaultHeight() {
      return this.title && this.title.length > 0 ? 128 : 64;
    },
  },
  watch: {
    opened(val) {
      this.height = this.$refs["heightEle"].clientHeight;
      this.outHeight =
        this.$refs["heightEle"].clientHeight +
        this.$refs["heightEle"].offsetTop;
    },
  },
  data() {
    return {
      opened: false,
      height: 0,
      outHeight: 0,
    };
  },
  methods: {
    handleAdd() {
      this.$emit("handleAdd");
    },
    handleSearch() {
      this.$emit("handleSearch");
    },
  },
};
</script>
<style lang="scss" scoped>
.search-collapse {
  background: #ffffff;
  box-sizing: border-box;
  padding: 16px;
  box-shadow: 0px 4px 8px 0px rgba(240, 241, 243, 1);
  border-radius: 4px;
  transition: all 0.3s;
  height: 0px;
  &-title {
    font-size: 20px;
    color: #252626;
    // line-height: 30px;
    font-weight: 600;
    margin-bottom: 23px;
  }
  &-container {
    /deep/ .el-form-item {
      .el-form-item__label,
      .el-form-item__content,
      .el-input__icon {
        line-height: 32px;
      }
      .el-input__inner {
        height: 32px;
      }
      .el-date-editor.el-date-editor--daterange {
        width: 372px;
        .el-icon-date {
          // line-height: 32px;
          position: absolute;
          right: 8px;
        }
        .el-range-separator {
          line-height: 24px;
          padding: 0 12px;
        }
        .el-range-input {
          text-align: left;
        }
      }
    }
    .add,
    .search {
      height: 32px;
      /deep/ .el-icon-plus {
        font-weight: 600;
      }
    }
    &-center {
      margin: 0 0 0 16px;
      transition: all 0.3s;
      overflow: hidden;
    }
    .search-right {
      height: 34px;
      .search {
        width: 64px;
      }
    }
  }
  &-senior {
    margin: 0 24px 0 0;
    color: #6ba3ff;

    .icon-arrow {
      margin-left: 4px;
      transition: transform 0.3s;
      width: 20px;
      &.is-opened {
        transform: rotateZ(180deg);
      }
    }
  }
}
</style>
