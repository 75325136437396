<template>
  <div class="chart-num">
    <div class="flex-h flex-vc">
      <span
        class="boll"
        :style="
          backgroundImage
            ? `background-image: ${backgroundImage};box-shadow: 0px 2px 4px 0px ${shadowColor};`
            : ''
        "
      ></span>
      <span class="flex-h flex-vc">
        {{ name }}
        <tip-text v-if="tipText" :tip-text="tipText" :size="12" />
      </span>
    </div>
    <div class="number">{{ num | number_format }}</div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    backgroundImage: {
      type: String,
      default: "",
    },
    shadowColor: {
      type: String,
      default: "",
    },
    num: {
      type: [String, Number],
      default: "0",
    },
    tipText: {
      type: String,
      default: "",
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.chart-num {
  font-size: 12px;
  color: #898a8c;
  line-height: 22px;
  .boll {
    width: 8px;
    height: 8px;
    border-radius: 50%;

    margin-right: 8px;
    margin-left: 3px;
  }
  .number {
    padding-left: 16px;
    font-size: 18px;
    color: rgba(51, 51, 51, 0.85);
    line-height: 28px;
    font-weight: 600;
  }
  /deep/ .tip-text {
    padding-left: 4px;
    margin-bottom: 0.09em;
  }
}
</style>
