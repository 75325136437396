<template>
  <el-dialog
    class="award-custom-dialog custom-dialog"
    :title="`${data && data.award_id ? '修改行权' : '新增行权'}`"
    :visible.sync="visible"
    :before-close="handleBeforeClose"
    :close-on-click-modal="false"
    width="576px"
  >
    <div>
      <el-form
        ref="form"
        :model="info"
        v-loading="getDetailLoading"
        :rules="formRules"
        hide-required-asterisk
        label-width="70px"
        class="custom-award"
      >
        <div class="form__title mt-24">参与人信息</div>
        <div class="from_item__both flex-h flex-hw">
          <el-form-item
            label="手机号"
            prop="phone"
            class="from_item__height_none"
          >
            {{ otherInfo.phone }}
          </el-form-item>
          <el-form-item label="姓名" class="from_item__height_none">
            {{ otherInfo.user_name || "---" }}
          </el-form-item>
          <el-form-item label="在职状态" class="from_item__height_none">
            {{
              tableMng.getNameById("employ_status", otherInfo.employ_status) ||
              "---"
            }}
          </el-form-item>
          <el-form-item label="所属部门" class="from_item__height_none">
            <TextOverflow :limit="10" :text="otherInfo.department || '- -'" />
          </el-form-item>
        </div>
        <div class="form__title">期权激励计划信息</div>
        <el-form-item label="授予编号" class="from_item__height_none">
          {{ otherInfo.code || "---" }}
        </el-form-item>
        <div class="from_item__three plan flex-h flex-hw">
          <el-form-item label="授予股数" class="from_item__height_none">
            {{ otherInfo.award_count || "---" }}
            <!-- <TextOverflow :limit="6" :text="planInfo.code || '- -'" /> -->
          </el-form-item>
          <el-form-item label="币种" class="from_item__height_none">
            {{ otherInfo.currency || "---" }}
          </el-form-item>
          <el-form-item label="行权参考价" class="from_item__height_none w-85">
            {{ otherInfo.price || "---" }}
            <!-- 888888.8888 -->
          </el-form-item>
          <el-form-item label="已成熟数" class="from_item__height_none">
            {{ otherInfo.mature_count || "---" }}
          </el-form-item>
          <el-form-item
            label="已行权数"
            class="from_item__height_none width-auto"
          >
            {{ otherInfo.actual_count || "---" }}
          </el-form-item>
        </div>

        <div class="form__title">行权信息</div>
        <el-form-item class="w-85" label="行权股数" prop="number">
          <el-input
            label=""
            v-model="info.number"
            placeholder="请输入行权股数"
            v-number-format="{ limit: 0, min: 1 }"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item class="w-85" label="实际行权价" prop="price">
          <el-input
            label=""
            v-model="info.price"
            v-number-format="{ limit: 4 }"
            placeholder="请输入实际行权价"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleBeforeClose">取消</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import api from "@/api";
import dialogLife from "@/mixins/dialogLife";
import TextOverflow from "@/components/base/text-overflow";
import { deepClone, formatDecimal } from "@/utils/core";
const defaultInfo = {
  number: "",
  price: "",
  award_id: "",
};

export default {
  mixins: [dialogLife],
  components: {
    TextOverflow,
  },
  computed: {},
  data() {
    return {
      infoBackup: deepClone(defaultInfo),
      info: deepClone(defaultInfo),
      otherInfo: {},
      formRules: {
        number: [
          { required: true, message: "请输入行权股数", trigger: "blur" },
        ],
        price: [
          { required: true, message: "请输入实际行权价", trigger: "blur" },
        ],
      },
    };
  },
  watch: {},
  methods: {
    async getDetail() {
      if (!this.data) return;
      this.getDetailLoading = true;
      let data = deepClone(this.data);
      this.otherInfo = data.otherInfo;
      this.info = data;
      this.infoBackup = data;
      this.getDetailLoading = false;
    },
    async submitMethod() {
      const { price, number, record_id } = this.info;
      const { award_id } = this.info.otherInfo;
      if (record_id)
        await api.awarded.optionExerciseEdit({
          record_id,
          price: formatDecimal(price, 4),
          number: formatDecimal(number, 0),
        });
      else
        await api.awarded.optionExerciseAdd({
          price: formatDecimal(price, 4),
          number: formatDecimal(number, 0),
          award_id,
        });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.info = deepClone(defaultInfo);
      this.infoBackup = deepClone(defaultInfo);
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss" scoped>
.award-custom-dialog {
  .w-100 {
    width: 100%;
  }
  /deep/ .el-dialog__body {
    padding-top: 0px;
    .el-form {
      padding-top: 0px;
    }
  }
  .custom-award {
    .mt-24 {
      margin-top: 24px;
    }
    .form__title {
      font-size: 14px;
      color: $black;
      line-height: 22px;
      font-weight: 600;
      padding-bottom: 12px;
      padding-top: 12px;
    }
    .w-85 {
      /deep/ {
        .el-form-item__label {
          width: 85px !important;
        }
        .el-form-item__content {
          margin-left: 85px !important;
        }
      }
    }
    .from_item__height_none {
      &.width-auto {
        width: auto !important;
      }
      /deep/ .el-form-item__label,
      /deep/ .el-form-item__content {
        line-height: 1;
      }
    }
    .from_item__both {
      /deep/.el-form-item {
        width: calc(50% - 16px);
        &:nth-child(2n) {
          margin-left: 32px;
        }
      }
    }
    // .from_item__three {
    //   /deep/.el-form-item {
    //     width: calc(33.33% - 20px);
    //     &:nth-child(3n - 1) {
    //       margin-left: 30px;
    //       margin-right: 30px;
    //     }
    //   }
    // }
    .from_item__three {
      /deep/.el-form-item {
        width: calc(33.33% - 20px);
        &:nth-child(3n - 1) {
          width: calc(27.33% - 20px);
          margin-left: 18px;
          margin-right: 18px;
        }
        &:nth-child(3n) {
          width: calc(39.33% - 26px);
        }
        &:nth-child(3n - 2) {
          width: calc(33.33% + 10px);
        }
      }
    }
    // .from_item__three.plan {
    //   /deep/.el-form-item {
    //     width: calc(33.33% - 20px);
    //     &:nth-child(3n) {
    //       width: calc(39.33% - 26px);
    //     }
    //     &:nth-child(3n - 2) {
    //       width: calc(33.33% + 10px);
    //     }
    //   }
    // }
  }
}
</style>
