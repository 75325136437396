<template>
  <!-- 分页 -->
  <div class="pagination clearfix">
    <el-pagination
      :class="`pagination--${position}`"
      :total="total"
      :current-page.sync="currentPage"
      :page-size.sync="pageSizeNum"
      :page-sizes="pageSizes"
      :layout="layout"
      :background="background"
      @size-change="handlePageSizeChange"
      @current-change="handlePageNumberChange"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      required: true,
      type: Number,
    },
    pageNumber: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50];
      },
    },
    layout: {
      type: String,
      default: "sizes, prev, pager, next, jumper",
    },
    background: {
      type: Boolean,
      default: true,
    },
    position: {
      validator: function (value) {
        return ["left", "center", "right"].includes(value);
      },
      default: "right",
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.pageNumber;
      },
      set(val) {
        this.$emit("update:pageNumber", val);
      },
    },
    pageSizeNum: {
      get() {
        return this.pageSize;
      },
      set(val) {
        this.$emit("update:pageSize", val);
      },
    },
  },
  methods: {
    handlePageSizeChange(val) {
      this.$emit("pagination", val);
    },
    handlePageNumberChange(val) {
      this.$emit("pagination", val);
    },
  },
};
</script>

<style lang="scss">
.pagination {
  // height: 32px;
  padding: 16px 0;

  &--left {
    float: left;
  }

  &--center {
    text-align: center;
  }

  &--right {
    float: right;
  }

  .el-pagination .el-pager {
    .number {
      font-weight: normal;
      color: #333;
    }

    .el-icon-more {
      border: none !important;
    }
  }
  .el-pagination .el-pagination__sizes .el-select .el-input {
    width: 96px;
    .el-input__suffix {
      right: 5px;
      font-weight: 600;
    }
    .el-input__inner {
      line-height: 22px;
      height: 32px;
      padding-right: 28px;
      padding-left: 12px;
    }
  }
  .el-pagination.is-background .btn-prev i,
  .el-pagination.is-background .btn-next i {
    font-weight: 600;
  }
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .el-pager li {
    background-color: #fff;
    border: 1px solid #d5d6d9;
    border-radius: 4px;
    width: 32px;
  }
  .el-pagination.is-background button,
  .el-pagination.is-background .el-pager li {
  }
  .el-pagination.is-background button[disabled] {
    background-color: #f5f7fa;
    color: #bbbdbf;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    border-color: $theme;
    background-color: $theme;
    color: $white;
  }
}
</style>
