<template>
  <div class="right-sta" v-loading="loading">
    <div class="flex-h flex-vc flex-jsb">
      <div class="left">
        <ChartNum
          name="授予人次"
          :num="option_stat.number"
          backgroundImage=""
          shadowColor=""
        />
        <ChartNum
          v-for="item in optionStatRe"
          :key="item.color"
          :name="item.name"
          :num="item.percent"
          :tipText="item.tipText || ''"
          :backgroundImage="item.lineColor"
          :shadowColor="item.shadowColor"
        />
      </div>
      <div class="chart-box">
        <Ring
          :name="'已授予'"
          :subtext="
            parseFloat(data.total) == 0
              ? '0%'
              : (
                  (
                    parseFloat(option_stat.award_qty) / parseFloat(data.total)
                  ).toFixed(2) * 100
                ).toFixed(2) + '%'
          "
          :value="
            parseFloat(data.total) == 0
              ? 0
              : (
                  (parseFloat(option_stat.award_qty) * 100) /
                  parseFloat(data.total)
                ).toFixed(2)
          "
          :color1="'#FF6B99'"
          :color2="'#f0427a'"
          :color3="'#FF1960'"
          :shadowcolor="'rgba(255,107,153,0.5)'"
          :id="'nujk'"
          :subColor="'#ECF0F3'"
          :height="172"
        ></Ring>
      </div>
    </div>
    <div class="flex-h flex-vc flex-jsb mt-20">
      <div class="left">
        <ChartNum
          name="授予总数(股)"
          :num="option_stat.award_qty"
          backgroundImage=""
          shadowColor=""
        />
        <ChartNum
          name="成熟总数(股)"
          :num="option_stat.mature_qty"
          tipText=""
          backgroundImage="linear-gradient(90deg, #F06000 0%, #FF9651 100%);"
          shadowColor="rgba(255,160,97,0.50)"
        />
      </div>
      <div class="chart-box">
        <Canvasdash
          :id="'chartstop3'"
          :value="
            parseFloat(option_stat.award_qty) == 0
              ? 0
              : parseFloat(option_stat.mature_qty) /
                parseFloat(option_stat.award_qty)
          "
          :height="74"
          :color1="'#F06000'"
          :color2="'#FF9651'"
          :shadowcolor="'rgba(255,160,97,0.5)'"
          :shadowcolor2="'rgba(255,160,97,0.3)'"
          :name="'已成熟'"
        ></Canvasdash>
      </div>
    </div>
    <div class="flex-h flex-vc flex-jsb mt-20">
      <div class="left">
        <ChartNum
          name="成熟总数(股)"
          :num="option_stat.mature_qty"
          backgroundImage=""
          shadowColor=""
        />
        <ChartNum
          name="行权总数(股)"
          :num="option_stat.actual_qty"
          tipText=""
          backgroundImage="linear-gradient(224deg, #57EB99 0%, #0DCD63 100%)"
          shadowColor="rgba(255,160,97,0.50)"
        />
      </div>
      <div class="chart-box">
        <Canvasdash
          :id="'chartsbottom12'"
          :value="
            parseFloat(option_stat.mature_qty) == 0
              ? 0
              : parseFloat(option_stat.actual_qty) /
                parseFloat(option_stat.mature_qty)
          "
          :height="74"
          :color1="'#0DCD63'"
          :color2="'#57EB99'"
          :shadowcolor="'rgba(87,235,153,0.5)'"
          :shadowcolor2="'rgba(87,235,153,0.3)'"
          :name="'已行权'"
        ></Canvasdash>
      </div>
    </div>
  </div>
</template>
<script>
import ChartNum from "@/components/business/chart-num";
// import RingLine from "@/components/charts/RingLine";
import Canvasdash from "@/components/charts/canvasdash";
import Ring from "@/components/charts/ring";
import api from "@/api";
export default {
  components: {
    ChartNum,
    // RingLine,
    Ring,
    Canvasdash,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    ["data.plan_id"](val, oval) {
      if (val !== oval) this.getDetail();
    },
  },
  computed: {
    optionStatRe() {
      const arr = JSON.parse(JSON.stringify(this.optionStat));
      return arr.reverse();
    },
  },
  data() {
    return {
      option_stat: {},
      optionStat: [],
      loading: true,
    };
  },
  mounted() {},
  methods: {
    getDetail() {
      const { plan_id } = this.data;
      if (!plan_id) {
        this.loading = false;
        return;
      }
      this.loading = true;
      api.incentive
        .statPlan({ plan_id, type: "option" })
        .then((res) => {
          if (res) {
            // console.log(res);
            this.loading = false;
            this.option_stat = res;
            this.optionStat = [
              {
                name: "剩余总数(股)",
                percent: Number(this.data.surplus),
                color: "l(0) 0:#57EB99 1:#0DCD63",
                lineColor:
                  "linear-gradient(rgb(236, 240, 243), rgb(236, 240, 243));",
                shadowColor: "rgba(0,0,0,0)",
              },
              {
                name: "授予总数(股)",
                percent: Number(res.award_qty),
                color: "l(0) 0:#FF1960 1:#FF6B99",
                lineColor: "linear-gradient(90deg, #FF1960 0%, #FF6B99 100%);",
                shadowColor: "rgba(255,107,153,0.5)",
                tipText:
                  "<div>统计中的“已授予数”和“已成熟数”与授予记录<br/>中的“授予股数”和“已成熟数”不是简单的汇<br />总关系。</div><div>考虑了“已终止”状态的授予记录中“已回收数”<br />对统计中“已授予数”和“已成熟数”的影响。</div>",
              },
              {
                name: "总股数(股)",
                percent: Number(this.data.total),
              },
            ];
          }
          // console.log(res);
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.right-sta {
  .chart_title {
    margin-bottom: 17px;
  }
  /deep/ .chart-num {
    margin-bottom: 6px;
  }
  .mt-20 {
    margin-top: 33px;
  }
}
</style>
