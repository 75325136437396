<template>
  <div class="exercise">
    <div class="chart_title mt-5 flex-h flex-jsb">
      <div class="flex-h flex-vc">
        <span>可行权数:</span>
        <span class="title_content">{{
          data.status === "stop" ? 0 : canNum | number_format(0)
        }}</span>
      </div>
      <el-button
        type="primary"
        plain
        size="mini"
        @click="handleEdit('', { otherInfo: data })"
        :disabled="data.status === 'stop'"
        >行权</el-button
      >
    </div>
    <div class="chart_title mt-40">行权记录</div>
    <el-table
      :data="tableData"
      highlight-current-row
      v-loading="tableLoading"
      @sort-change="changeSort"
      @row-click="rowClick"
      :row-class-name="tableRowClassName"
      ref="table"
      class="custom_border mt-4"
      height="calc(100% - 164px)"
    >
      <el-table-column prop="" label="操作" min-width="80px">
        <template slot-scope="scope">
          <el-button
            v-if="data.status === 'normal'"
            type="text"
            @click="handleEdit(scope.$index, { ...scope.row, otherInfo: data })"
          >
            修改
          </el-button>
          <span v-else>---</span>
        </template>
      </el-table-column>
      <af-table-column prop="number" label="行权股数" />
      <af-table-column prop="price" label="实际行权价" align="right" />

      <el-table-column prop="ctime" label="创建时间" min-width="195px" />
      <af-table-column prop="creator" label="创建人" min-width="80px" />
      <el-table-column prop="mtime" label="修改时间" min-width="196px" />
      <af-table-column prop="modifier" label="修改人" min-width="80px" />
    </el-table>
    <ExerciseEdit
      :data="edit_data"
      :visible="visible"
      @onClose="handleClose"
      @onSave="handleSave"
    />
  </div>
</template>
<script>
import api from "@/api";
import ExerciseEdit from "./ExerciseEdit.vue";
import tableLife from "@/mixins/tableLife.js";
export default {
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
  watch: {
    data(value) {
      const { award_id } = value;
      if (award_id) this.getList();
    },
  },
  mixins: [tableLife],
  components: {
    ExerciseEdit,
  },
  computed: {
    canNum() {
      return this.data.mature_count - this.data.actual_count;
    },
  },
  data() {
    return {
      total: 0,
      queryCondition: {
        sort: "ctime",
        order: "desc",
      },
      defaultSortWord: "",
      visible: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getApiTableData() {
      if (!this.data.award_id) return;
      return api.awarded.optionExerciseList({
        award_id: this.data.award_id,
      });
      // const data = await api.awarded.optionExerciseList({
      //   award_id: this.data.award_id,
      // });
      // return data;
    },
    // 保存
    handleSave() {
      this.handleClose();
      this.$parent.$parent.$parent.getList();
      this.getList();
    },
    // 关闭编辑模态窗
    handleClose() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.exercise {
  height: 100%;
  .chart_title {
    .title_content {
      font-size: 20px;
      color: #333333;
      line-height: 28px;
      font-weight: 600;
      margin-left: 24px;
    }
  }
}
</style>
